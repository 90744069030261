import React, { useState } from 'react';
import './App.css';
import { bitcoin } from 'bitcoinjs-lib/src/networks';
import ECPairFactory from 'ecpair';



function App() {
  const ecc = require('tiny-secp256k1');

const ECPair = ECPairFactory(ecc);
const network = bitcoin.networks.testnet; 

  const [address, setAddress] = useState(null);
  const [privateKey, setPrivateKey] = useState(null);

  const createWallet = () => {
    try {
      const keyPair = ECPair.makeRandom({ network: network });
      const { address } = bitcoin.payments.p2pkh({
        pubkey: keyPair.publicKey,
        network: network,
      });
      const privateKey = keyPair.toWIF();

      setAddress(address);
      setPrivateKey(privateKey);

      console.log(`Public Address: ${address}`);
      console.log(`Private Key: ${privateKey}`);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="App">
      <header className="App-header">
        <h4>Create Bitcoin Wallet</h4>
        <button onClick={createWallet}>Create Address</button>
        {address && <p>Address: {address}</p>}
        {privateKey && <p>Private Key: {privateKey}</p>}
      </header>
    </div>
  );
}

export default App;
